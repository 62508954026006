.accordionContent ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;

  margin-bottom: 1rem;
  font-family:
    Inter,
    Helvetica Neue,
    Arial,
    sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: hsl(var(--gray-dark));
}

.accordionContent a {
  color: #00688d;
}

.accordionContent p > a[href]:not(.btn) {
  text-decoration-line: underline;
}
